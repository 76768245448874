<template>
  <b-overlay
      :show="spinnerShow === true"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
  >
  <component :is="data === undefined ? 'div' : 'b-card'">
    <!-- Header -->
    <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
      <h5 class="mb-0">
        Edit Diabetes Type
      </h5>
    </div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="data === undefined"
    >
      <h4 class="alert-heading">
        Error fetching data
      </h4>
      <div class="alert-body">
        No data found with this id. Click
        <b-link
          class="alert-link"
          :to="{ name: 'dashboard'}"
        >
          here
        </b-link>
        to go back to Dashboard.
      </div>
    </b-alert>

    <b-tabs
      v-if="data"
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Details</span>
        </template>
        <validation-observer ref="simpleRules">
          <!-- Form -->
          <b-form
            class="p-2"
            @reset.prevent="resetForm"
          >

            <!-- Full Name -->
            <validation-provider
              #default="validationContext"
              name="Name"
              rules="required"
            >
              <b-form-group
                label="Name"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="data.name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Show Name"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Start -->
            <validation-provider
              #default="validationContext"
              name="abbr"
            >
              <label>Abbreviation</label>
              <b-form-input
                id="abbr"
                v-model="data.abbr"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="any Abbreviation"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="end"
              rules="required"
            >
              <label>Is Active</label>
              <v-select
                  v-model="data.active"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="user-status"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click.prevent="validationForm"
              >
                Update Changes
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="$router.push({name: 'system-diabetes-type-list'})"
              >
                Cancel
              </b-button>
            </div>

          </b-form>
        </validation-observer>
      </b-tab>
    </b-tabs>
  </component>
  </b-overlay>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormInvalidFeedback,
  BFormTimepicker,
  BModal,
  VBModal,
  BFormTextarea,
  BOverlay,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import router from '@/router'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTimepicker,
    vSelect,
    BModal,
    BFormTextarea,
    VBModal,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BOverlay,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  setup() {
    const toast = useToast()
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const blankData = {
      name: '',
      start: '',
      end: '',
    }

    const resetData = () => {
      this.data = JSON.parse(JSON.stringify(blankData))
    }

    const statusOptions = [
      { label: 'Active', value: 1 },
      { label: 'Inactive', value: 0 },
    ]

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    return {
      statusOptions,
      refInputEl,
      previewEl,
      refFormObserver,
      getValidationState,
      resetForm,
      toast,
    }
  },
  data() {
    return {
      required,
      alphaNum,
      spinnerShow: true,
      daysOptions: [],
      userList: [],
      data: [],
      selected_days: [],
      selected_users: [],
    }
  },
  created() {
    this.getEdit()
  },
  methods: {
    getEdit() {
      store.dispatch('diabetesTypeStore/fetchDiabetesType', { pubId: router.currentRoute.params.id })
        .then(response => {
          this.data = response.data
          this.spinnerShow = false
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.data = undefined
          }
        })
    },

    validationForm() {
      this.spinnerShow = true
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          store.dispatch('diabetesTypeStore/updateDiabetesType', this.data)
            .then(() => {
              this.spinnerShow = false
              this.$bvToast.toast('The details has been updated', {
                title: 'Information Updated',
                variant: 'success',
                solid: true,
              })
            }).catch(() => {
              this.$bvToast.toast('Details could not be updated', {
                title: 'Error Occurred',
                variant: 'danger',
                solid: true,
              })
            })
        }
      })
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
